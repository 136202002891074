<template>
  <mp-box
    id="qon-header"
    as="nav"
    border-bottom-color="gray.100"
    border-bottom-width="1px"
    border-style="solid"
    background-color="white"
    padding-x="6"
    padding-y="2"
    height="60px"
  >
    <mp-flex
      flex-direction="row"
      justify-content="space-between"
      align-items="center"
    >
      <mp-flex
        id="qon-header-left-side"
        flex-direction="row"
        justify-content="space-between"
        align-items="center"
      >
        <!-- Logo on mobile -->
        <mp-box
          id="qon-header-logo"
          as="router-link"
          to="/"
          bg-color="sky.400"
          rounded="full"
          p="1.5"
          :display="{
            xl: 'none',
            lg: 'none',
            md: 'none',
            sm: 'block',
            xs: 'block',
          }"
        >
          <img
            src="@/assets/images/brands/new/qontak-c.svg"
            alt="Mekari Qontak Chat"
          />
        </mp-box>
        <!-- Logo on desktop -->
        <mp-box
          id="qon-header-logo"
          as="router-link"
          to="/"
          :display="{
            xl: 'block',
            lg: 'block',
            md: 'block',
            sm: 'none',
            xs: 'none',
          }"
        >
          <img
            src="@/assets/images/brands/new/logo-qontak-default.svg"
            alt="Mekari Qontak Chat"
          />
        </mp-box>
        <template v-if="!isQontakLitePackage">
          <!-- Divider -->
          <mp-box
            ml="4"
            height="1.5rem"
            border="1px"
            color="gray.100"
            :display="{
              xl: 'block',
              lg: 'block',
              md: 'block',
              sm: 'none',
              xs: 'none',
            }"
          >
          </mp-box>
          <!-- Switcher Product -->
          <mp-box
            ml="4"
            :display="{
              xl: 'block',
              lg: 'block',
              md: 'block',
              sm: 'block',
              xs: 'block',
            }"
          >
            <mp-popover id="header-app-switcher-popover" use-portal>
              <mp-popover-trigger>
                <mp-button
                  color="gray.600"
                  variant="link"
                  right-icon="caret-down"
                  margin-y="2"
                >
                  Omnichannel
                </mp-button>
              </mp-popover-trigger>
              <mp-popover-content
                max-width="64"
                bg="white"
                rounded="md"
                shadow="lg"
                border-width="1px"
                border-color="gray.400"
              >
                <mp-popover-list>
                  <mp-box width="full" padding-x="3" padding-y="2">
                    <mp-text color="gray.600" font-size="sm" text-align="left">
                      SELECT APPS
                    </mp-text>
                  </mp-box>
                  <mp-popover-list-item>
                    Omnichannel
                    <mp-icon name="check" size="sm" margin-right="2" />
                  </mp-popover-list-item>
                  <mp-popover-list-item @click="switchToCrm()">
                    CRM
                  </mp-popover-list-item>
                  <mp-popover-list-item
                    v-if="kbInfo.kb_active"
                    @click="switchToKB()"
                  >
                    <mp-text> Knowledge Base </mp-text>
                    <mp-badge variant-color="red">New</mp-badge>
                  </mp-popover-list-item>
                  <mp-popover-list-item
                    v-if="
                      appConfig &&
                      appConfig.is_launchpad &&
                      $auth &&
                      $auth.user.is_launchpad_superadmin &&
                      isQontakSuiteOrg
                    "
                    style="border-top: 1px solid #dcdfe4"
                    pt="4"
                    @click="switchToLaunchpad()"
                  >
                    Admin console
                  </mp-popover-list-item>
                  <mp-popover-list-item
                    v-if="appConfig && isCanAccessInsight && !isAgent()"
                    style="border-top: 1px solid #dcdfe4"
                    pt="4"
                    @click="switchToInsight()"
                  >
                    <mp-text> Insight </mp-text>
                    <mp-badge variant-color="red">New</mp-badge>
                  </mp-popover-list-item>
                </mp-popover-list>
              </mp-popover-content>
            </mp-popover>
          </mp-box>
        </template>
      </mp-flex>
      <mp-box id="qon-header-content">
        <mp-flex justify-content="space-between" align-items="center">
          <mp-stack
            is-inline
            :spacing="{ xl: '4', lg: '3', md: '2', sm: '1', xs: '1' }"
          >
            <mp-box
              v-if="isSupervisor() || isAgent()"
              id="qon-header-content-online"
              class="centered-item"
            >
              <mp-box ref="offlinePopover">
                <mp-popover
                  id="online-offline-popover"
                  :is-open="showOnlineOfflineAlert"
                  is-manual
                >
                  <mp-popover-trigger>
                    <mp-flex class="centered-item">
                      <mp-toggle
                        id="qon-header-content-online-toggle"
                        v-model="is_online"
                        :is-disabled="loading.toggle"
                        m="0"
                        @change="onToggleStatusChange"
                        >{{ is_online ? 'Online' : 'Offline' }}</mp-toggle
                      >
                    </mp-flex>
                  </mp-popover-trigger>
                  <mp-popover-content
                    max-width="17rem"
                    bg-color="white"
                    p="4"
                    border-radius="md"
                    border="1px solid"
                    border-color="gray.100"
                  >
                    <mp-text font-weight="semibold" font-size="md"
                      >You're offline</mp-text
                    >
                    <mp-text
                      >Switching online will allow you to be assigned
                      automatically.</mp-text
                    >
                  </mp-popover-content>
                </mp-popover>
              </mp-box>
            </mp-box>
            <mp-box id="qon-header-content-help" class="centered-item">
              <mp-popover id="header-help-popover">
                <mp-popover-trigger>
                  <mp-box as="button">
                    <mp-tooltip position="bottom" label="Help center">
                      <mp-icon name="help"></mp-icon>
                    </mp-tooltip>
                  </mp-box>
                </mp-popover-trigger>
                <mp-popover-content
                  max-width="64"
                  bg-color="white"
                  p="4"
                  border-radius="md"
                  border="1px solid"
                  border-color="gray.100"
                >
                  <mp-text font-size="xs" color="gray.600">Resources</mp-text>
                  <mp-pseudo-box
                    :_hover="{
                      backgroundColor: 'ice.50',
                      color: 'blue.400',
                      cursor: 'pointer',
                    }"
                    px="1"
                  >
                    <mp-flex
                      mt="2"
                      mb="3"
                      cursor="pointer"
                      @click="
                        openTab(
                          'https://help-center.qontak.com/hc/id/articles/5703349565209-Omnichannel-Frequently-Asked-Questions-FAQs-'
                        )
                      "
                    >
                      <mp-icon name="chat" mr="2" size="sm" />
                      <mp-text size="sm">FAQ</mp-text>
                    </mp-flex>
                  </mp-pseudo-box>
                  <mp-text font-size="xs" color="gray.600">Help</mp-text>
                  <mp-pseudo-box
                    :_hover="{
                      backgroundColor: 'ice.50',
                      color: 'blue.400',
                      cursor: 'pointer',
                    }"
                    px="1"
                  >
                    <mp-flex
                      mt="2"
                      cursor="pointer"
                      @click="openTab('https://app.qontak.com/contact-support')"
                    >
                      <img
                        src="@/assets/images/icons/icon-whatsapp.svg"
                        alt="Whatsapp"
                        width="20"
                      />
                      <mp-text size="sm" ml="2">Customer Support</mp-text>
                    </mp-flex>
                  </mp-pseudo-box>
                </mp-popover-content>
              </mp-popover>
            </mp-box>
            <mp-box id="qon-header-content-notification">
              <NotificationBell />
            </mp-box>
            <mp-box id="qon-header-content-switcher">
              <mp-tooltip position="bottom" label="Switch app">
                <mp-button-icon
                  name="shortcuts"
                  size="md"
                  @click="isSwitchAppOpen = !isSwitchAppOpen"
                />
              </mp-tooltip>
            </mp-box>
          </mp-stack>
          <SwitchAccount @onLoggedOut="setStatusToOffline" />
        </mp-flex>
      </mp-box>
    </mp-flex>
    <SwitchApp
      :is-switch-app-open="isSwitchAppOpen"
      :on-switch-app-toggle="onSwitchAppToggle"
    />
    <Notification />
    <transition name="slide-down">
      <StickyToast v-if="stickyToast.isShow" />
    </transition>
  </mp-box>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapState } from 'vuex'
import Notification from '../UI/Notification.vue'
import SwitchApp from './SwitchApp.vue'
import SwitchAccount from './main/SwitchAccount.vue'
import NotificationBell from './main/Notification.vue'
import StickyToast from '~/components/UI/StickyToast.vue'
import { isQontakLitePackage } from '~/utils/general'

export default {
  name: 'LayoutsHeader',
  components: {
    SwitchApp,
    SwitchAccount,
    NotificationBell,
    Notification,
    StickyToast,
  },
  data() {
    return {
      isSwitchAppOpen: false,
      loading: {
        toggle: false,
      },
      showOnlineOfflineAlert: false,
    }
  },
  head() {
    return {
      titleTemplate: `${
        this.info.unassigned ? '(' + this.info.unassigned + ') ' : ''
      }%s - Qontak Chat`,
      link: [
        {
          rel: 'icon',
          type: 'image/x-icon',
          href:
            this.info.unassigned > 0
              ? '/qontak-favicon-unread.png'
              : '/qontak-favicon.ico',
        },
      ],
    }
  },
  computed: {
    ...mapFields('layouts', ['userStatus.is_online']),
    ...mapState('layouts', [
      'appConfig',
      'userStatus',
      'stickyToast',
      'kbInfo',
    ]),
    ...mapState('inbox', ['info']),
    ...mapState('preferences', ['billing_info']),
    isQontakLitePackage() {
      return isQontakLitePackage(this.billing_info)
    },
    isQontakSuiteOrg() {
      return this.billing_info?.package_name.includes('Suites')
    },
    isCanAccessInsight() {
      const cidsList = this.appConfig?.is_company_can_access_insight
      const result = cidsList.includes(
        parseInt(this.credentials?.[0]?.company_id)
      )
      return result
    },
  },
  created() {
    this.$nuxt.$on('onLoggedOut', () => {
      this.setStatusToOffline()
    })
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside)
    if (typeof this.$auth?.user?.is_online === 'boolean') {
      this.$store.commit('layouts/UPDATE_USER_STATUS', {
        is_online: this.$auth.user.is_online,
        status: this.$auth.user.is_online ? 'online' : 'offline',
      })
    } else {
      this.$store.commit('layouts/UPDATE_USER_STATUS', {
        is_online: this.userStatus.is_online,
        status: this.userStatus.is_online ? 'online' : 'offline',
      })
    }
    this.checkOnline()
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleClickOutside)
  },
  methods: {
    onSwitchAppToggle() {
      this.isSwitchAppOpen = false
    },
    openTab(url) {
      window.open(url)
    },
    switchToCrm() {
      this.$store
        .dispatch('users/getUserByEmail', {
          email: this.$auth.user.email,
          is_crm_check: true,
        })
        .then((res) => {
          let redirectUrl = process.env.CRM_URL
          const dhlCids = this.appConfig.dhl_company_ids

          if (dhlCids.includes(res?.data?.organization?.company_id)) {
            redirectUrl = 'https://dhl.qontak.com'
          }
          this.$toast({
            variant: 'success',
            title: 'You will be redirected to the CRM',
            position: 'top',
          })
          this.$auth.reset()
          setTimeout(() => {
            window.location.replace(
              `${process.env.SSO_URL}/auth/?client_id=${process.env.SSO_UNIFIED_CLIENT_ID}&response_type=code&scope=sso:profile&redirect_uri=${redirectUrl}/sso-callback`
            )
          }, 1000)
        })
        .catch((err) => {
          if (err) {
            this.$toast({
              variant: 'error',
              title:
                'Your account is unregistered to Qontak CRM yet. Contact our support team for further info',
              position: 'top',
            })
          }
        })
    },
    switchToLaunchpad() {
      const redirectUrl = process.env.QONTAK_LAUNCHPAD_URL
      window.location.replace(
        `${process.env.SSO_URL}/auth/?client_id=${process.env.SSO_UNIFIED_CLIENT_ID}&response_type=code&scope=sso:profile&redirect_uri=${redirectUrl}/sso-callback/switcher`
      )
    },
    switchToInsight() {
      const redirectUrl = process.env.QONTAK_LAUNCHPAD_URL
      window.location.replace(
        `${process.env.SSO_URL}/auth/?client_id=${process.env.SSO_UNIFIED_CLIENT_ID}&response_type=code&scope=sso:profile&redirect_uri=${redirectUrl}/sso-callback/switcher`
      )
    },
    onToggleStatusChange(status) {
      if (status) this.setStatusToOnline()
      else this.setStatusToOffline()
    },
    updateUserStatus(status) {
      this.loading.toggle = true
      this.$store
        .dispatch('layouts/updateUserStatus', {
          is_online: status,
          status: status ? 'online' : 'offline',
        })
        .then(() => {
          setTimeout(() => {
            this.loading.toggle = false
          }, 800)
        })
        .catch(() => {
          setTimeout(() => {
            this.loading.toggle = false
          }, 800)
          this.$toast({
            variant: 'error',
            title: 'Failure to switch status. Try again in second',
            position: 'top',
          })
        })
    },
    setStatusToOnline() {
      this.updateUserStatus(true)
    },
    setStatusToOffline() {
      this.updateUserStatus(false)
    },
    checkOnline() {
      if (this.isAgent() || this.isSupervisor()) {
        if (!this.is_online) this.showOnlineOfflineAlert = true
      }
    },
    closeModalOnlineOfflineAlert() {
      this.showOnlineOfflineAlert = false
    },
    handleClickOutside(e) {
      this.closeModalOnlineOfflineAlert()
    },
    switchToKB() {
      if (this.isSupervisor() || this.isAgent())
        window.location.replace(this.kbInfo.kb_client_url)
      else window.location.replace(`https://${process.env.KB_ADMIN_URL}`)
    },
  },
}
</script>

<style scoped lang="scss">
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-down-enter-active {
  transition: all 0.3s ease-in-out;
}
.slide-down-leave-active {
  /* transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0); */
  transition: all 0.3s ease-in-out;
}
.slide-down-enter, .slide-down-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(-60px);
  opacity: 0;
}
</style>
